<template>
  <v-container fluid>
    <v-layout row wrap>
      <h1>Acerca de...</h1>

      <v-row dense>
        <div class="col-sm-12 col-md-8">
          <v-card class="mr-1 mb-1">
            <v-card-title class="headline">Titulo tarjeta</v-card-title>
            <v-card-subtitle>Sub-texto tarjeta</v-card-subtitle>

            <v-card-text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
              esse iusto architecto qui, repellat delectus enim debitis est. In
              numquam labore tempora aliquam beatae ab id commodi voluptate
              accusantium et!
            </v-card-text>

            <div class="d-flex flex-wrap">
              <v-card-text class="py-0 col-xs-12 col-sm-6">
                <VTextField label="Nombre" placeholder=" "></VTextField>
              </v-card-text>

              <v-card-text class="py-0 col-xs-12 col-sm-6">
                <VTextField label="Apellidos" placeholder=" "></VTextField>
              </v-card-text>
            </div>

            <v-card-actions>
              <Button text color="primary">
                Guardar
              </Button>

              <Button text color="primary">
                Cancelar
              </Button>

              <v-spacer />
              <router-link
                to="/login"
                v-slot="{ href, route, navigate }"
              >
                <a :href="href" @click="navigate" class="subheading mx-3"
                  >Ir a Login view (ruta: {{ route.fullPath }})</a
                >
              </router-link>
            </v-card-actions>
          </v-card>
        </div>

        <div class="col-md-4">
          <v-card>
            <v-card-title class="headline">Titulo tarjeta</v-card-title>
            <v-card-subtitle>Sub-texto tarjeta</v-card-subtitle>

            <v-card-text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
              esse iusto architecto qui, repellat delectus enim debitis est. In
              numquam labore tempora aliquam beatae ab id commodi voluptate
              accusantium et!
            </v-card-text>
          </v-card>
          <!-- <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">Our Changing Planet</v-list-item-title>
              <v-list-item-subtitle>by Kurt Wagner</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item> -->
        </div>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import Button from "@/components/ui/Button";

export default {
  components: {
    Button,
  }
}
</script>